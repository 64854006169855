/*
* Colors
*/
$color-primary: #fe5d00;
$color-secondary: #161e2e;
$color-dark-primary: #0d1321;
$color-tertiary: #f0f4ff;
$color-gray: #4a4f61;
$color-light: #ffffff;
$color-shadow: rgba(1, 6, 33, 0.06);

/*
orbit colors
*/

$azure-radiance: #1877f2;
$dodger-blue: #1d9bf0;
$amethyst: #c63fcd;
$guardsman-red: #cc0000;
$red-ribbon: #f60d4f;
$light-purple: #e2ddff;
$light-orange: #fae0d0;
$light-blue: #bbf0ff;
$light-green: #cef6d6;
$dark-purple: #5444a5;
$dark-orange: #f68c20;
$dark-blue: #36bbf0;
$dark-green: #5fd46d;

/*
* Fonts
*/
$font-heading: 'Poppins', sans-serif;
$font-display: 'Inter', sans-serif;
